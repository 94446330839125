html, body{
  min-height: 100%;
  height: 100%;
}

#root{
  height: 100%;
}

#root > .container-fluid{
  height: 100%;
}

#root > .container-fluid > .container {
  /* height: calc(100% - 388px); */
  min-height: calc(100% - 222px);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fancy-pic-body {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -5px;
  min-height: 28px;
  background-color: #fff;
  width: 90px;
  height: 60px;
  margin: 0 auto;
}

.fancy-pic {
  width: 60px;
  height: 60px;
  margin: 0 !important;
  padding: 0 !important;
}

.bottom-image{
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 100px;
}

.rate ul{
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 52px;
  z-index: 100;
  background: #5481a1;
  color: #fff;
  text-align: left;
}

.rate ul li{
  padding: 8px;
}


.dropdown.open .dropdown-menu{
  display: block;
}

.sliderrr{
  position: relative;
}

.sliderrr .fa-angle-left,
.sliderrr .fa-angle-right{
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  font-size: 65px;
  cursor: pointer;
  color: #c1c1c1;
  z-index: 100;
}

.sliderrr .fa-angle-left{
  left: -35px;
}
.sliderrr .fa-angle-right{
  right: -35px;
}

.sliderrr .slick-slider{
  width: 100%;
}

.collapse.in{
  display: block !important;
}

.pointer{
  cursor: pointer;
}
.text-dark.text-decoration-none.pointer{
  padding-bottom: .5rem !important;
}
.text-dark.text-decoration-none.pointer.active{
  border-bottom: 3px solid #31A993;
}

.groupTitle{
  display: flex;
  align-items: center;
}

.groupTitle img{
  margin-right: 12px;
}

.groupbody{

}

.groupbody small{
  font-family: Open Sans;
font-style: normal;
font-weight: normal;
font-size: 11px;
line-height: 15px;
/* identical to box height */
color: #4A4A4A;
}

.progressGroup{
  background: #C4C4C4;
  border-radius: 30px;
  height: 12px;
  width: 100%;
  margin: 5px 0;
  position: relative;
}

.progressGroup > div{
  background: #915674;
  border-radius: 30px;
  height: 100%;
  width: 0%;
}

.progressGroup > span{
  width: 6px;
  height: 100%;
  display: inline-block;
  position: absolute;
  top:0;
  background-color: #fff;
  margin-left: -3px;
}

.progressGroup > span:nth-child(2){
  left: 20%;
}
.progressGroup > span:nth-child(3){
  left: 40%; 
}
.progressGroup > span:nth-child(4){
  left: 60%; 
}

.progressGroup > span:nth-child(5){
  left: 80%;
}

.admin_click{
  position: absolute;
  top: 0;
  right: -55px;
  width: 40px;
  height: 40px;
  z-index: 100;
  
}
.admin_click a{
  width: 100%;
  height: 100%;
  border: 1px solid gray;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  font-size: 14px;
  font-weight: 400;
}
.space-between{
  justify-content: space-between;
}

.js-image-zoom__zoomed-image{
  z-index: 1000;
  right: 97px !important;
}

.headImage > div:last-child{
z-index: 1000;
}

.modal{
  display: block !important;
  background-color: #4e4e4e66;
}
.last-page-btn{
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #000;
}

.last-page-btn:hover{
  text-decoration: none;
  color: #000;
}

.img-zoom-container {
  position: relative;
}

.img-zoom-lens {
  position: absolute;
  border: 1px solid #d4d4d4;
  /*set the size of the lens:*/
  width: 40px;
  height: 40px;
  opacity: 0;
}
.img-zoom-lens.show {
  opacity: 1;
}


.img-zoom-result {
  border: 1px solid #d4d4d4;
  /*set the size of the result div:*/
  width: 400px;
  height: 400px;
  position: absolute;
  top: 0;
  right: -327px;
  z-index: -1;
  /* display: none; */
  /* right: 0; */
  opacity: 0;
}

.img-zoom-result.show {
  /* display: block; */
  /* right: -327px; */
  opacity: 1;
  z-index: 1000;
}

.zoomImagg{

}

.zoomImagg > div{
  left: 103px;
  margin-top: 64px;
}



.zoomImagg > div img{
  border-radius: 100%;
  width: 350px !important;
  height: 350px !important;
  object-fit: contain;
}

.homeImg{
  border-radius: 100%;
  width: 350px;
  height: 350px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.textAeraView{
  width: 100%;
  height: 181px;
  background: #FFFFFF;
  border: 1px solid #C4C4C4;
  box-sizing: border-box;
  border-radius: 30px;
  padding: 8px 12px;
  resize: none;
}
.control {
  position: relative;
}
.control .fa-eye{
  position: absolute;
  top: 47px;
  right: 15px;
  color: gray;
  cursor: pointer;
}
.fa-star {
  color: rgb(255, 193, 7) !important; 
}
.fa-star-o{
  color:#bfbfbf;
}

.fa-thumbs-up, .fa-thumbs-down{
  color: #915674;
}
.input-rounded-fields{
  width: 100% !important;
}
.adjust-width{
  width: 463px !important
}
.feedbackImg{
  width: 52px;
    height: 52px;
    background-color: #d8d8d8;
    border-radius: 100%;
}

.textAeraView::placeholder {
  color: #31A993;
  opacity: 1; /* Firefox */
}

textarea:hover, 
input:hover, 
textarea:active, 
input:active, 
textarea:focus, 
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active
{
    outline:0px !important;
    /* -webkit-appearance:none; */
    box-shadow: none !important;
}


.flexCricle{
  display: flex;
  align-items: center;
}

.flexCricle > span{
  width: 10px;
  height: 10px;
  border-radius: 100px;
  border: 1px solid #915674;
  margin: 0 2px;
}

.flexCricle > span.active{
  background-color: #915674;
}

.progressRate{
  background-color: #F0F0F0;
  height: 12px;
  width: 160px;
  margin: 0 12px;
}

.progressRate > div{
  background-color: #915674;
  height: 100%;
  width: 0;
}

.tableRate td{
  /* padding-bottom: 10px; */
}

.imageItems{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.imageItems > div{
  width: 200px;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}

.upload{
  position:absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

input[type="checkbox"]{

}

.slick-prev:before, .slick-next:before{
  color: #9a9a9a !important;
}

.sliderrr .slick-prev:before, .sliderrr .slick-next:before{
  color: #fff !important;
}

.noactive{
  pointer-events: none;
  opacity: 0.5;
}

.order{
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: .25rem;
}

.order-header{
  padding: .75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0,0,0,.03);
  border-bottom: 1px solid rgba(0,0,0,.125);
}

.order-body{
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
}

.f-weight-5{
  font-weight: 500;
}

.order-img{
  width: 80px;
  height: 80px;
  border-radius: 100%;
  border: 1px solid gray;
  object-fit: cover;
}

.statusOrder{
  color: #fff;
  background-color: coral;
  padding: 4px 8px;
  font-size: 12px;
  border-radius: 3px;
}
.infoStatus{
  color: gray;
}
.infoStatus span{
  font-weight: 500;
  color: #000;
}

.f-jcenter{
  justify-content: center;
}
.f-jbetween{
  justify-content: space-between;
}

.f-acenter{
  align-items: center;
}

.f-col{
  flex-direction: column;
}

.f-row{
  flex-direction: row;
}

.cardInput .input{
  height: 48px;
  border: 1px solid gray;
}

.cardInput .input.braintree-hosted-fields-focused{
  /* border-color: red; */
}

.footer-subscript{
  /* position: absolute;
  bottom: -72px; */
  width: 277px;
}

.footer-subscript input[type="email"]{
  border-radius: 0 !important;
}
.footer-subscript input[type="submit"]{
  border-radius: 0 !important;
  width: 100% !important;
  border: 0 solid;
  color: transparent;
}


.stripForm form {
  width: 100%;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}
.stripForm input {
  border-radius: 6px;
  margin-bottom: 6px;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  font-size: 16px;
  width: 100%;
  background: white;
  box-sizing: border-box;
}
.stripForm .result-message {
  line-height: 22px;
  font-size: 16px;
}
.stripForm .result-message a {
  color: rgb(89, 111, 214);
  font-weight: 600;
  text-decoration: none;
}
.stripForm .hidden {
  display: none;
}
.stripForm #card-error {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  margin-top: 12px;
  text-align: center;
}
.stripForm #card-element {
  border-radius: 4px 4px 0 0;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  width: 100%;
  background: white;
  box-sizing: border-box;
}
.stripForm #payment-request-button {
  margin-bottom: 32px;
}
/* Buttons and links */
.stripForm button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 0 0 4px 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}
.stripForm button:hover {
  filter: contrast(115%);
}
.stripForm button:disabled {
  opacity: 0.5;
  cursor: default;
}
/* spinner/processing state, errors */
.stripForm .spinner,
.stripForm .spinner:before,
.stripForm .spinner:after {
  border-radius: 50%;
}
.stripForm .spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.stripForm .spinner:before,
.stripForm .spinner:after {
  position: absolute;
  content: "";
}
.stripForm .spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}
.stripForm .spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}
@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@media only screen and (max-width: 600px) {
  .stripForm form {
    width: 80vw;
  }
}
.mostImg{
  width: 180px;
  height: 180px;
  object-fit: contain;
  margin: 0 10px;
}

.seeMore{
  box-shadow: 0px 0px 20px 0 #80808047;
  padding: 8px 16px;
}

.seeMore a{
  color: #2A8B60;
  font-size: 12px;
}

.seeMoreImg{
  width: 100px;
  height: 80px;
  object-fit: contain;
}

.seeMoreTitle{
font-size: 12px;
}

.flexSeeMore{

}

.mostProducts{
  box-shadow: 0px 0px 20px 0 #80808047;
  padding: 25px 16px;
  margin-bottom: 25px;
}
.homeMainSlider{
  background: linear-gradient(180deg, #57E1A4 0%, rgba(255, 255, 255, 0) 53.73%)
}
.mostProducts2 .slick-slider{
  width: 92% !important;
}
.mostProducts .slick-slider{
  width: 96%;
  margin: 0 auto;
}



.sliderrr2{
  position: relative;
}

.sliderrr2 .fa-angle-left,
.sliderrr2 .fa-angle-right{
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  font-size: 65px;
  cursor: pointer;
  color: #c1c1c1;
  z-index: 100;
}

.sliderrr2 .fa-angle-left{
  left: 12px;
}
.sliderrr2 .fa-angle-right{
  right: 43px;
}

.sliderrr2 .slick-slider{
  width: 100%;
}
.mostProducts .slick-slider .slick-arrow{
  width: 15px;
  height: 28px;
  background-image: url('./assets/svg/arrow.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

}

.mostProducts .slick-slider .slick-arrow.slick-prev{

}

.mostProducts .slick-slider .slick-arrow.slick-next{
  transform: rotate(180deg) translate(0, 50%);
}

.mostProducts .slick-slider .slick-arrow.slick-prev::before{
 content: '';
}

.mostProducts .slick-slider .slick-arrow.slick-next::before{
  content: '';
}