.Stars{

}

.Stars i{
    cursor: pointer;
}

.fa_star{
    color:#ffc107;
}