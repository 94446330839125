
.top-home-bar {
    height: 52px;
    /* background: #5481A1; */
}

.bg-navbar {
    background: #FFFFFF;
}

.top-search-input {

    width: 226px;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 30px;
}

.four-icons-top {
    padding-left: 66px;
}

.buy-btn {
    width: 170px;
    height: 63px;
    background: #915674;
    border-radius: 30px;
    border: none;
}

.buy-next-btn {
    width: 190px;
    height: 63px;
    background: #FFFFFF;
    border: 2px solid #915674;
    color: #915674;
    box-sizing: border-box;
    border-radius: 30px;
}

.packet-lft-sctn {
    padding-top: 130px;
    padding-left: 100px;
}

.circle-btm {
    margin-top: 200px;
    margin-left: -376px;
    position: sticky;
}

.circle-top {
    position: absolute;
    margin-top: -37rem;
    margin-left: 22rem;
}

.login-buttons {
    width: 163px;
    height: 54px;
    background: #915674;
    border-radius: 30px;
    color: white;
    border: none;
}

.input-rounded-fields {
    width: 431px;
    height: 46px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 30px;
}

.login-sctns {
    padding: 100px;
}

.text-btm-border {

    border-bottom: 1px solid #915674;
    padding-bottom: 10px;
    width: 130px;
}

.bg-black {
    background: #000000;
}

.footer-input {
    width: 100%;
    text-align: center;
    height: 54px;
    background: #000000;
    border: 1px solid #FFFFFF;
    border-radius: 60px;
}

button:focus {
    outline: none;
}

.footer-input:focus {
    outline: none;
    border: 1px solid #915674;
}

.footer-input::placeholder {
    color: #c9c9c9;
}

.footer-btn {
    width: 200px;
    height: 54px;
    background: #FFFFFF;
    border-radius: 60px;
}

.footer-row {
    padding-top: 56px;
    padding-bottom: 110px;
}

.navbar {
    padding: 0px;
}

.nav-pdng {
    padding-left: 60px;
}

.footer-row ul li {
    padding: 5px 5px;
}

.footer-circles {
    margin-top: -125px;
    margin-left: -107px;
}

.last-footer-sctn {
    padding: 5px 100px;
}

.tablee {
    /* padding: 30px 100px; */
}

.tablee .table .tbl-head-border th {
    border-top: none;
}

.tablee td {
    border-top: none;
}

.coupn-main {
    width: 363px;
    height: 44px;
    background: #F5F3F3;
    border-radius: 30px;
}

.coupn-grey {
    width: 363px;
    height: 44px;
    background: #F5F3F3;
    border-radius: 30px;
}

.coupn-green {

    width: 200px;
    height: 44px;
    background: #8DA56C;
    border-radius: 30px;
}

.updt-cart-btn {
    width: 143px;
    height: 44px;
    background: #F5F3F3;
    border-radius: 30px;
}

.chkout-btn {
    width: 223px;
    height: 44px;
    background: #915674;
    border-radius: 30px;
}

.btn-sctns {
    padding: 30px 0px;
}

.cart-hdng-brdr-btm {
    border-bottom: 2px solid #915674;
    width: 141px;
    padding-bottom: 5px;
}

.total-checkout-sctn {
    padding-top: 180px;
}

.coupn-sctn-pddng {
    padding-left: 40px;
}

.cart-last-sctn-hdng-brdr {
    border-bottom: 2px solid #915674;
}

.accordion .fa {
    margin-right: 0.5rem;
}

.card-body {
    padding-left: 30px;
}

.bs-example-two {
    padding-left: 20px;
}

.card button:focus {
    box-shadow: none;
}

.card-header {
    background: none;
    padding: 0px;
}

.card {
    border: none;
    border-bottom: 1px solid lightgrey !important;
}

.btn-link {
    color: black;
}

.btn-link:hover {
    color: black;
    text-decoration: none;
}

.card h2 button {
    font-weight: bolder;
}

.product-list-div {
    width: 245px;
    height: 245px;
    background: #FFFFFF;
    border: 1px solid #B8B8B8;
    box-sizing: border-box;
    border-radius: 140px;
    position: relative;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
}

.product-custom {
    width: 157px;
    height: 215px;
}

.product-btm-sctn {
    padding-right: 57px;
    padding-top: 10px;
}

.sort-by {
    border: 1px solid lightgray;
    padding: 2px 15px;
    border-radius: 7px;
    margin-left: 17px;
}

.forecly-pdng {
    padding-left: 225px;
}

.hover-main-sctn {
    width: 245px;
    height: 245px;
    background: #5481A1;
    opacity: 0.9;
    border-radius: 140px;
}

.hover-top-title {
    width: 43px;
    height: 43px;
    background: #FFFFFF;
    border-radius: 140px;
    padding: 5px;
    color: black;
}

.hover-add-cart-btn {
    color: white;
    width: 132px;
    height: 40px;
    background: #762b54;
    border-radius: 60px;
    border: none;

}

.hover-view-item-btn {
    width: 132px;
    height: 40px;
    background: #FFFFFF;
    border: 2px solid #915674;
    box-sizing: border-box;
    border-radius: 60px;

}

.hover-effect-div {
    position: absolute;
    top: 250px;
    z-index: -99;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
}

.hover-effect-div2 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    opacity: 0;
    /* visibility: hidden; */
    transition: all 0.3s ease-in-out;
}

.product-list-div:hover .hover-effect-div {
    top: -50px;
    visibility: visible;
    bottom: inherit;
    z-index: 999;
    transition: all 0.3s ease-in-out;
}

 .hover-effect-div2:hover {
    opacity: 1;
    transition: all 0.3s ease-in-out;
}

.chkout-top-info {
    height: 48px;
    background: #915674;
    border-left: 5px solid #703F58;
}

.checkout-input-small {
    height: 46px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 30px;
}

.checkout-input-large {
    width: 100%;
    height: 46px;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 30px;
}

.checkout-message {
    width: 100%;
    height: 146px !important;
    background: #FFFFFF;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 18px;
    margin-top: 5px;
}

.chkout-grey-sctn {
    width: 100%;
    padding: 30px;
    background: #F5F5F5;
    border-radius: 16px;
}

.payment-sections {
    width: 100%;
    /* height: 260px; */
    background: #3A3A3A;
    border-radius: 16px;
    padding: 30px;
    padding-bottom: 22px;
}

.checkout-final-btn {
    width: 100%;
    height: 44px;
    background: #915674;
    color: white;
    border-radius: 30px;
    border: 1px solid #915674;
}

.checkout-final-btn-line {
    width: 100%;
    height: 44px;
    background: #fff;
    color: #915674;
    border: 1px solid #915674;
    border-radius: 30px;
}

.text-theme {
    color: #915674;
    font-weight: 500;
}

.checkout-paragraph {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
}

.checkout-main-section {
    margin-top: 80px;
}

.border-theme {
    border-bottom: 2px solid #915674;
}

.second-product-hdngs {
    font-size: 20px;
}

.circle-second-product {
    width: 73px;
    height: 73px;
    background: #76ABC4;
    border-radius: 140px;
    margin: 0 auto;
}

.center-margin {
    margin-left: 50%;
}

.fancy-img-inner-text-div {
    text-align: center;
    position: sticky;
}

.color-mehroon {
    color: #915674;
}

.forty-circle-div {
    width: 43px;
    height: 43px;
    background: #76ABC4;
    border-radius: 140px;
    padding: 5px;
    color: white;
    font-weight: bolder;
}

.fa-star {
    color: #915674;
}

.heading-font {
    font-size: 26px;
}

.plus-minus-div {
    width: 100%;
    height: 54px;
    background: #FFFFFF;
    border: 1px solid #B8B8B8;
    box-sizing: border-box;
    display: flex;
    border-radius: 30px;
    /* padding-right: 21px; */
    /* padding-top: 6px; */
    align-items: center;
    justify-content: space-evenly;
}

.plus-minus-size {
    font-size: 26px;
    color: black;
}

.long-cart-btn {
    width: 400px;
    height: 54px;
    background: #915674;
    border-radius: 30px;
    color: white;
    font-weight: bolder;
    border: none;
}

.font-size-custom {
    font-size: 22px;
}

.slider {
    -webkit-appearance: none;
    width: 231px;
    height: 9px;
    background: #d3d3d3;
    outline: none;
    -webkit-transition: .2s;
    transition: opacity .2s;
}

.slider:hover {
    opacity: 1;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 17px;
    height: 15px;
    background: #915674;
    cursor: pointer;
}

.slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #915674;
    cursor: pointer;
}

.last-page-btn {
    width: 164px;
    height: 41px;
    background: #FFFFFF;
    border: 1px solid #915674;
    box-sizing: border-box;
    border-radius: 4px;
}

.last-grey-sctn {
    height: 94px;
    background: #FAFAFA;
}

.margin-left {
    margin-left: 41.5rem;
}

.bg-grey {
    background: #EEEEEE;
}

.custom-select-sctn {
    width: 164px;
    height: 41px;
}

.last-page-prgrph {
    font-size: 18px;
    font-weight: 400;
}

.modal .modal-dialog {
    width: 100% !important;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 670px;
        margin-top: 12rem;
    }
}

.rounded-modal-div {
    border: 1px solid lightgrey;
    border-radius: 140px;
}

.second-side-modal {
    border-bottom: 4px solid #B9B9B9;
}

.text-footer-modal {
    color: #5481A1;
}

.modal-hdng {
    margin-left: 32rem;
}

.modal-content {
    border-radius: 30px;
}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
    padding-right: 0px;
    padding-left: 0px;
}

.total-fancy-div {
    border: 1px solid lightgrey;
    border-radius: 240px;
    width: 125px;
    height: 125px;
}

.fancy-pic {
    margin-top: -34px;
    margin-left: 37px;
    padding-bottom: 17px;
}

.checkout-main-section input::placeholder {
    color: #31A993;

}


@media (max-width: 500px) {

    .container {
        width: 100% !important;
    }

    .display-block {
        display: block !important;
        padding: 0px !important;
    }

    .sm-section-header {
        width: 100% !important;
        padding: 0px !important;
    }

    .home-img-hide svg {
        width: 400px;
        height: 250px;
    }

    .circle-btm {
        margin-top: 0px;
        margin-left: -376px;
        position: sticky;
    }

    .top-home-bar {
        width: 100%;
    }

    .padding-remove {
        padding: 5px 0px !important;
    }

    .packet-lft-sctn {
        padding: 14px 35px;
        text-align: center;
    }

    .home-buttons {
        margin: 0px !important;
        margin-top: 10px !important;
    }

    .full-with {
        width: 100% !important;
    }

    .display-block {
        display: block !important;
        padding: 0px;
    }

    .login-sctns {
        padding: 10px 10px;
    }

    .footer-circles {
        display: none;
    }

    .input-rounded-fields {
        width: 280px;
    }

    .btn-sctns {
        padding: 0px;
    }

    .coupn-sctn-pddng {
        padding-left: 0px;
    }

    .tablee {
        padding: 0px;
    }

    .coupn-main {
        margin-bottom: 20px;
    }

    .total-checkout-sctn {
        padding-left: 50px;
        padding-right: 50px;
        padding-top: 87px;
    }

    footer {
        text-align: center;
    }

    .footer-input {
        width: 200px;
    }

    .footer-btn {
        width: 210px;
    }

    .checkout-input-small {
        width: 370px;
    }

    .chkout-grey-sctn {
        width: 354px;
    }

    .payment-sections {
        width: 313px;
    }

    .checkout-final-btn {
        width: 310px;
    }

    .chkout-top-info {
        height: 65px;
    }

    .forecly-pdng {
        padding-left: 0px;
        display: block !important;
    }

    .sort-by {
        width: 100%;
    }

    .small-screen-center {
        text-align: center;
    }

    .small-center {
        padding-left: 50px;
    }

    #innercollapseOne {
        text-align: justify;
    }

    .center-margin {
        margin: 0px;
    }

    .margin-left {
        margin: 0px;
    }

    .plus-minus-div {
        width: 50%;
    }

    .long-cart-btn {
        width: 50%;
        margin: 0px !important;
    }

    .last-grey-sctn {
        padding: 10px;
        height: auto;
        background: #FAFAFA;
    }

    .padding-zero-small {
        padding: 0px !important;
    }
}

.popup-cover-div {
    width: 100%;
    height: 969px;
    max-width: 100%;
    background-image: url("../images/popup-cover.png");
    background-size: cover;
}

.popup-text-size {
    font-size: 26px;
    margin: 5px 25px;
    background: #f2f0f0;
    border-radius: 130px;
    border: none;
    padding: 10px 30px;
}

.header-pdng {
    padding-left: 60px;
}

.second-product-img {
    width: 100%;
}

.select-other-img:hover {
    border: 3px solid #76ABC4;
    border-radius: 40px;
}

.select-img-btn:focus {
    outline: none;
}

.footer-input-sctn {
    margin-top: -336px;
    width: 321px;
    position: relative;
    left: -28px;
}

.cart-plus-div {
    border-radius: 140px;
    border: 1px solid lightgrey;
    width: 102px;
}

.contact-btn {
    width: 243px;
    height: 54px;
    background: #915674;
    border-radius: 30px;
    color: white;
    font-weight: 600;
    border: none;
}

.forget-btn {
    width: 173px;
    height: 44px;
    background: #915674;
    border-radius: 30px;
    color: white;
    font-weight: 600;
    border: none;
}

.contact-us-form input::placeholder {
    color: #31A993 !important;
}

.cntct-map-img {
    width: 380px;
    height: 450px;
}

.forget-sctn {
    padding-left: 420px;
}

.forget-inpt {
    width: 332px;
}

.accnt-btn {
    width: 213px;
    height: 54px;
    background: #915674;
    border-radius: 30px;
    color: white;
    font-weight: 600;
    border: none;
}

.color-from-theme {
    color: #31A993;
}

.text-size-custom {
    font-size: 23px;
    font-weight: 500;
}

.single-product-collapsed {
    font-weight: Bolder;
    font-size: 23px;
}



.accnt-details-img {
    width: 145px;
    height: 145px;
    background: #E3E3E3;
    border-radius: 145px;
}

.placeholder-color-theme::placeholder {
    color: #31A993;
}

.table-cart tr td{
    vertical-align: middle;
}
.img-class{
    width: 70px;
    height: 70px;
    border: 1px solid lightgrey;
    border-radius: 140px;
    /* padding: 8px; */
}
.select-custom-styling{
    height: 46px;
    border-radius: 30px;
    border: 1px solid #C4C4C4;
}
.select-theme-color{
    color: #31A993 ;
}
.pointing{
    cursor: pointer;
}
.select-custom-styling:focus{
    outline: none;
}
.selecting-custom-styling{
    border: 1px solid #C4C4C4;
    width: 71px;
    height: 46px;
    border-radius: 30px;
}
.selecting-custom-styling:focus{
    outline: none;
}
.adjust-width{
    width: 355px;
}

.thanks-h1{
    font-weight: 800;
    font-size: 65px;
    line-height: 89px;
    /* identical to box height */

    text-transform: uppercase;

    color: #000000;
}

.thanks-p{
font-size: 17px;
line-height: 23px;
text-align: center;

color: #4A4A4A;
}